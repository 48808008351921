import { jsx as o } from "react/jsx-runtime";
import { useMemo as m } from "react";
import { Alert as a, CheckmarkCircle as l, AlertCircle as t } from "@machineq/icons";
/* empty css                         */
import { Alert as i } from "../Alert/Alert.js";
import { Toaster as u } from "../Toast/Toaster.js";
import { useToast as p } from "../Toast/toast.useToast.js";
import { exhaustiveMatchGuard as f } from "../utils/exhaustiveMatchGuard.js";
import { Icon as A } from "../../src/1_atoms/icon/Icon.js";
const e = "toast-alert", C = "alertToastStyles_andx2ln", d = ({
  closeToast: s,
  dxVariant: r,
  ...n
}) => {
  const c = m(() => {
    switch (r) {
      case "error":
        return {
          Icon: t,
          mqColor: "danger"
        };
      case "info":
        return {
          Icon: t,
          mqColor: "grey-4"
        };
      case "success":
        return {
          Icon: l,
          mqColor: "success"
        };
      case "warning":
        return {
          Icon: a,
          mqColor: "warning"
        };
      default:
        return f();
    }
  }, [r]);
  return /* @__PURE__ */ o(i, {
    ...n,
    dxVariant: r,
    DXAdornment: /* @__PURE__ */ o(A, {
      ...c,
      mqAccessibility: "decorative"
    }),
    className: C,
    dxOnClose: s
  });
}, v = () => /* @__PURE__ */ o(u, {
  id: e,
  ToastComponent: d
}), M = () => p({
  id: e
});
export {
  d as ToastAlert,
  v as ToasterAlert,
  M as useToastAlert
};
