import { jsx as s } from "react/jsx-runtime";
import { styled as a } from "@linaria/react";
import { useLayoutContext as e, LayoutProvider as u } from "./Layout.context.js";
/* empty css                     */
const n = /* @__PURE__ */ a("div")({
  name: "SLayout",
  class: "SLayout_s1cs40t4",
  propsAsIs: !1
}), y = ({
  children: t,
  ...o
}) => {
  const {
    layoutRef: r
  } = e();
  return /* @__PURE__ */ s(n, {
    ...o,
    ref: r,
    children: t
  });
}, f = ({
  children: t,
  mobileLayout: o = "default",
  ...r
}) => /* @__PURE__ */ s(u, {
  mobileLayout: o,
  children: /* @__PURE__ */ s(y, {
    ...r,
    "data-test-id": "layout",
    children: t
  })
});
export {
  f as Layout,
  y as LayoutInner,
  n as SLayout
};
